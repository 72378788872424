import PageHeader from '../PageHeader'
import React, {ReactNode, useContext, useEffect, useState} from 'react'
import {UserContext} from '../UserContext'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import {Button} from '@mui/material'
import Section from '../Section'
import {useNavigate} from 'react-router-dom'
import Breadcrumbs from '../Breadcrumbs'
import navigation from '../Navigation'
import {mapTypesToNames} from './AlarmType'
import {AlarmTrigger, listAlarms} from '../Api'


type Status = 'INITIALIZED' | 'LOADING' | 'OK' | 'ERROR'

interface AlarmDataProps {
  alarms: AlarmTrigger[]
}

const AlarmData: React.FC<AlarmDataProps> = ({alarms}) => {
  const navigate = useNavigate()
  return (
    <TableContainer>
      <Table sx={{minWidth: 650}} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Messpunkt ID</TableCell>
            <TableCell>UUID</TableCell>
            <TableCell>Empfänger</TableCell>
            <TableCell>Zeitzone</TableCell>
            <TableCell>Typ</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {alarms.map((row) => (
            <TableRow
              onClick={() => navigate(navigation.alarmUpdate.path, {state: {alarm: row}})}
              key={row.meterId + '-' + row.uuid}
              sx={{'&:last-child td, &:last-child th': {border: 0}}}
            >
              <TableCell sx={{cursor: "pointer"}} component="th" scope="row">{row.name}</TableCell>
              <TableCell sx={{cursor: "pointer"}} component="th" scope="row">{row.meterId}</TableCell>
              <TableCell sx={{cursor: "pointer"}}>{row.uuid}</TableCell>
              <TableCell sx={{cursor: "pointer"}}>{row.recipients.join(', ')}</TableCell>
              <TableCell sx={{cursor: "pointer"}}>{row.zone}</TableCell>
              <TableCell sx={{cursor: "pointer"}}>{mapTypesToNames(row.type)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const body = (status: Status, alarms?: AlarmTrigger[]): ReactNode => {
  switch (status) {
    case 'INITIALIZED':
      return <span>Initialisiere…</span>
    case 'LOADING':
      return <span>Lade…</span>
    case 'OK':
      return <AlarmData alarms={alarms || []}/>
    case 'ERROR':
      return <span>Fehler!</span>
  }
}

const AlarmsOverview: React.FC = () => {
  const {user} = useContext(UserContext)
  const [status, setStatus] = useState<Status>('INITIALIZED')
  const [alarms, setAlarms] = useState<AlarmTrigger[]>([])
  const navigate = useNavigate()

  useEffect(() => {
      if (user === null) {
        return
      }
      setStatus('LOADING')
      listAlarms(user)
        .then(result => result
          .success(value => {
            setAlarms(value.alarms)
            setStatus('OK')
          })
          .error(_ => setStatus('ERROR'))
        )
    }, [user]
  )
  const title = 'Alarme'
  const pageHeader = <PageHeader
    title={title}
    description={'Hier finden Sie konfigurierten Alarme'}
    actions={[
      <Button
        variant="contained"
        onClick={() => navigate(navigation.alarmCreate.path)}
      >
        {navigation.alarmCreate.name}
      </Button>,
    ]}/>

  return <>
    <Breadcrumbs current={title}/>
    <Section pageHeader={pageHeader}>
      {body(status, alarms)}
    </Section>
  </>
}

export default AlarmsOverview
