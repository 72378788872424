import {User} from './UserContext'
import * as uuid from 'uuid'
import Result from './Result'
import Meter from './Meter'
import {AlarmType} from './alarms/AlarmType'
import {FixBudgetDetails, IndividualBudgetDetails} from './alarms/AlarmCreateBudget'
import ReportTrigger from './ReportTrigger'
import {Company} from './Company'
import {readConfig} from './index'


type HttpError = "Server Error"

export interface ListMetersListApiResponse {
  meters: ListMetersMeterApiResponse[]
}

export interface ListMetersMeterApiResponse {
  companyId: string
  id: string
  dataSources: {
    id: string
  }[]
  scale: number
  interpolationInterval: number
  input: string
  outputs: string[]
  label: string
  type: string
}

export interface ListReportTriggersListApiResponse {
  reports: ListReportTriggersApiResponse[]
}

export interface ListReportTriggersApiResponse {
  id: string
  recipientAddress: string
  reportName: string
  meterIds: string[]
  start: string
  recurrence: string
  companyName: string
  displayName: string
  companyId: string
}

export interface ListCompaniesApiResponse {
  companies: CompanyApiResponse[]
}

export interface CompanyApiResponse {
  id: string
  name: string
  logo?: string
}

export type CreateAlarmError = "generic error" | HttpError

const transform = (alarm: any, trigger: AlarmType) => {
  const uniqueId = alarm.uuid || uuid.v4()
  switch (trigger) {
    case 'BudgetTrigger': {
      const budgetDetails = alarm as FixBudgetDetails | IndividualBudgetDetails
      switch (budgetDetails.periodImplementation) {
        case 'FixPeriod': {
          const fixDetails = budgetDetails as FixBudgetDetails
          return {
            name: alarm.name,
            meterId: alarm.meterId,
            uuid: uniqueId,
            recipients: alarm.recipients,
            zone: alarm.timezone,
            type: alarm.type,
            maximum: fixDetails.maximum,
            periodImplementation: fixDetails.periodImplementation,
            periodType: fixDetails.period.type,
          }
        }
        case 'IndividualPeriod': {
          const individualDetails = budgetDetails as IndividualBudgetDetails
          return {
            name: alarm.name,
            meterId: alarm.meterId,
            uuid: uniqueId,
            recipients: alarm.recipients,
            zone: alarm.timezone,
            type: alarm.type,
            maximum: individualDetails.maximum,
            periodImplementation: individualDetails.periodImplementation,
            period: `P${individualDetails.period.span}${individualDetails.period.type}`,
          }
        }
      }
      break
    }
    case 'AusreisserTrigger':
    case 'PlausibilitaetTrigger':
    case 'SchwellwertTrigger':
    case 'MesswertkontrolleTrigger':
    default:
      return {
        ...alarm,
        zone: alarm.timezone,
        'uuid': uniqueId,
      }
  }
}

export const createAlarmTrigger = async (user: User, alarm: any, trigger: AlarmType): Promise<Result<void, CreateAlarmError>> => {
  const value = transform(alarm, trigger)
  const response = await fetch(`${readConfig().baseUrl}/web/alarms`, {
    method: 'POST',
    headers: {'Authorization': 'Bearer ' + user.idToken},
    body: JSON.stringify(value),
  })
  return response.ok ?
    Result.success(undefined) :
    Result.error("generic error")
}

export type DeleteAlarmError = "generic error"

export type GenericError = "generic error"

export const deleteAlarmTrigger = async (user: User, meterId: string, uuid: string): Promise<Result<void, DeleteAlarmError>> => {
  const response = await fetch(`${readConfig().baseUrl}/web/alarms`, {
    method: 'DELETE',
    headers: {'Authorization': 'Bearer ' + user.idToken},
    body: JSON.stringify({meterId, uuid}),
  })
  return response.ok ?
    Result.success(undefined) :
    Result.error("generic error")
}

export type ListMetersError = "generic error" | HttpError

const mapApiMeter = (apiMeter: ListMetersMeterApiResponse): Meter => ({
  id: apiMeter.id,
  input: apiMeter.input,
  dataSources: apiMeter.dataSources.map(apiDatasource => ({id: apiDatasource.id})),
  interpolationInterval: apiMeter.interpolationInterval,
  label: apiMeter.label,
  outputs: apiMeter.outputs,
  scale: apiMeter.scale,
  type: apiMeter.type,
  companyId: apiMeter.companyId,
})

export const listMeters = async (user: User): Promise<Result<Meter[], ListMetersError>> => {
  const response = await fetch(`${readConfig().baseUrl}/web/meters`, {
    method: 'GET',
    headers: {'Authorization': 'Bearer ' + user.idToken},
  })
  if (!response.ok) {
    return Result.error("generic error")
  }
  const json = await response.json()
  const apiResponse = json as ListMetersListApiResponse
  const meters: Meter[] = apiResponse.meters.map(apiMeter => mapApiMeter(apiMeter))
  return Result.success(meters)
}

const mapApiReportTrigger = (apiReportTrigger: ListReportTriggersApiResponse): ReportTrigger => ({
  id: apiReportTrigger.id,
  recipientAddress: apiReportTrigger.recipientAddress,
  reportName: apiReportTrigger.reportName,
  meterIds: apiReportTrigger.meterIds,
  start: apiReportTrigger.start,
  recurrence: apiReportTrigger.recurrence,
  companyName: apiReportTrigger.companyName,
  displayName: apiReportTrigger.displayName,
  companyId: apiReportTrigger.companyId,
})


interface CreateReportTriggerRequest {
  id: string | undefined
  recipientAddress: string
  // reportName: string
  meterIds: string[]
  // start: string
  recurrence: string
  // companyName: string
  displayName: string
  companyId: string | undefined
}

export const createReportTrigger = async (user: User, request: CreateReportTriggerRequest): Promise<Result<void, GenericError>> => {
  const response = await fetch(`${readConfig().baseUrl}/web/reports`, {
    method: 'POST',
    headers: {'Authorization': 'Bearer ' + user.idToken},
    body: JSON.stringify(request),
  })
  return response.ok ?
    Result.success(undefined) :
    Result.error("generic error")
}

export const listReportTriggers = async (user: User): Promise<Result<ReportTrigger[], GenericError>> => {
  const response = await fetch(`${readConfig().baseUrl}/web/reports`, {
    method: 'GET',
    headers: {'Authorization': 'Bearer ' + user.idToken},
  })
  if (!response.ok) {
    return Result.error("generic error")
  }
  const json = await response.json()
  const apiResponse = json as ListReportTriggersListApiResponse
  const reportTriggers: ReportTrigger[] = apiResponse.reports.map(apiTrigger => mapApiReportTrigger(apiTrigger))
  return Result.success(reportTriggers)
}

export const deleteReportTrigger = async (user: User, companyId: string, id: string): Promise<Result<void, GenericError>> => {
  const response = await fetch(`${readConfig().baseUrl}/web/reports`, {
    method: 'DELETE',
    headers: {'Authorization': 'Bearer ' + user.idToken},
    body: JSON.stringify({companyId, id}),
  })
  return response.ok ?
    Result.success(undefined) :
    Result.error("generic error")
}

interface UpsertCompanyRequest {
  id?: string
  name: string
  files: FileList | null
}

interface UpsertCompanyApiRequest {
  id?: string
  name: string
  logo?: FileData
}

interface FileData {
  type: string
  data: string
  name: string
}

const reader = new FileReader()

const transformFile = async (file: File): Promise<string | null> => {
  return new Promise((resolve, reject) => {
    reader.readAsDataURL(file)
    // @ts-ignore
    // todo
    reader.onload = () => resolve(reader.result)
    reader.onerror = _ => reject(null)
  })
}

const extractFile = (files: FileList | null): File | null => {
  if (files === null) {
    return null
  }
  const file = files[0]
  if (file === null) {
    return null
  }
  return file
}

const parseFile = async (files: FileList | null): Promise<FileData | undefined> => {
  const file = extractFile(files)
  if (file === null) {
    return undefined
  }
  const base64File = await transformFile(file)
  if (base64File === null) {
    return undefined
  }
  const indexOfComma = base64File.indexOf(",")
  const type = base64File
    .substring(0, indexOfComma)
    .substring(5)
    .replace(";base64", "")
  const data = base64File.substring(indexOfComma + 1)
  const name = file.name
  return {type, data, name}
}

export const upsertCompany = async (user: User, request: UpsertCompanyRequest): Promise<Result<void, GenericError>> => {
  const apiRequest: UpsertCompanyApiRequest = {
    id: request.id,
    name: request.name,
    logo: await parseFile(request.files)
  }

  const response = await fetch(`${readConfig().baseUrl}/web/companies`, {
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + user.idToken,
    },
    body: JSON.stringify(apiRequest)
  })
  return response.ok ?
    Result.success(undefined) :
    Result.error("generic error")
}

export const listCompanies = async (user: User): Promise<Result<Company[], GenericError>> => {
  const response = await fetch(`${readConfig().baseUrl}/web/companies`, {
    method: 'GET',
    headers: {'Authorization': 'Bearer ' + user.idToken},
  })
  if (!response.ok) {
    return Result.error("generic error")
  }
  const json = await response.json()
  const apiResponse = json as ListCompaniesApiResponse
  const companies: Company[] = apiResponse.companies
    .map(apiResponse => ({
      id: apiResponse.id,
      name: apiResponse.name,
      logo: apiResponse.logo,
    }))
    .sort((a, b) => a.name.localeCompare(b.name))
  return Result.success(companies)
}

export const deleteCompany = async (user: User, id: string): Promise<Result<void, GenericError>> => {
  const response = await fetch(`${readConfig().baseUrl}/web/companies`, {
    method: 'DELETE',
    headers: {'Authorization': 'Bearer ' + user.idToken},
    body: JSON.stringify({id}),
  })
  return response.ok ?
    Result.success(undefined) :
    Result.error("generic error")
}

export const changeCompany = async (user: User, id: string): Promise<Result<void, GenericError>> => {
  const response = await fetch(`${readConfig().baseUrl}/web/users/switch-company`, {
    method: 'POST',
    headers: {'Authorization': 'Bearer ' + user.idToken},
    body: JSON.stringify({id}),
  })
  return response.ok ?
    Result.success(undefined) :
    Result.error("generic error")
}

export interface AlarmTrigger {
  name: string
  meterId: string
  uuid: string
  threshold: number
  recipients: string[],
  zone: string,
  type: AlarmType
}

export interface AlarmResponse {
  alarms: AlarmTrigger[]
}

type ListAlarmsError = "error loading alarms"

export const listAlarms = async (user: User): Promise<Result<AlarmResponse, ListAlarmsError>> => {
  const response = await fetch(`${readConfig().baseUrl}/web/alarms`, {
    headers: {'Authorization': 'Bearer ' + user.idToken}
  })
  return response.ok ?
    Result.success(await response.json()) :
    Result.error("error loading alarms")
}
