import React from 'react'
import {Box, Typography} from '@mui/material'
import theme from '../Theme'


interface Props {
  text: string
  example?: string
}

const AlarmTriggerDescription: React.FC<Props> = ({text, example}) => {
  return (<Box sx={{
    mb: 2,
    bgcolor: theme.pageHeader.bgColor,
    p: 2
  }}>
    <Typography>
      {text}
    </Typography>
    {
      example === undefined ? <></> :
      <Typography variant={"caption"}>
        <Box>Beispiel</Box> {example}
      </Typography>
    }
  </Box>)
}

export default AlarmTriggerDescription
