import {User} from './UserContext'
import React from 'react'
import {Chip} from '@mui/material'


interface UserProps {
  user: User
}

const mapSalutation = (user: User) => {
  switch (user.salutation) {
    case 'MR':
      return 'Herr'
    case 'MRS':
      return 'Frau'
    case 'NEUTRAL':
    default:
      return ''
  }
}

const Greeting: React.FC<UserProps> = ({user}) => {
  const greeting = `Herzlich willkommen`
  if (user.name === undefined) {
    return <span>{greeting}!</span>
  }
  return (
    <div>
      <div>{greeting} {mapSalutation(user)} {user.name}!</div>
      <Chip color="info"
            size="small"
            label={user.role}/>
    </div>
  )
}

export default Greeting
