class Result<S, E> {

  private constructor(private s: S | null, private e: E | null) {
    if (s !== null && e !== null) {
      throw new Error("Set either success or error not both!")
    }
  }

  static success<S, E>(success: S): Result<S, E> {
    return new Result<S, E>(success, null)
  }

  static error<S, E>(error: E): Result<S, E> {
    return new Result<S, E>(null, error)
  }

  success(successFn: (value: S) => void): Result<S, E> {
    if (this.s !== null) {
      successFn(this.s)
    }
    return this
  }

  error(errorFn: (value: E) => void): Result<S, E> {
    if (this.e !== null) {
      errorFn(this.e)
    }
    return this
  }

  finally(fn: () => void) {
    fn()
  }
}

export default Result
