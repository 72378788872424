import React, {useEffect, useState} from 'react'
import ThresholdField from '../ThresholdField'
import AlarmTriggerDescription from './AlarmTriggerDescription'


export interface AusreisserDetails {
  threshold: number
}

interface Props {
  measurand?: string
  threshold?: number
  onChange: (details: AusreisserDetails) => void
}


const AlarmCreateAusreisser: React.FC<Props> = ({measurand, threshold, onChange}) => {
  const [selectedThreshold, setSelectedThreshold] = useState(threshold || 0)
  useEffect(() => {
    onChange({threshold: selectedThreshold})
  }, [selectedThreshold, onChange])
  return (<>
    <AlarmTriggerDescription
      text={"Die Ausreißerkontrolle prüft zwei aufeinanderfolgende Messwerte eines Messpunktes hinsichtlich relativer Abweichung"}
      example={"Alarm wird ausgelöst wenn die Differenz vom Messwert um 17:15 Uhr und dem Messwert um 17:00 Uhr größer als X ist"}
    />
    <ThresholdField
      label={"Schwellwert"}
      onChange={setSelectedThreshold}
      value={selectedThreshold}
    />
  </>)
}

export default AlarmCreateAusreisser
