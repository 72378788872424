import React, {useContext, useEffect, useState} from 'react'
import PageHeader from '../PageHeader'
import Breadcrumbs from '../Breadcrumbs'
import Section from '../Section'
import {Button} from '@mui/material'
import navigation from '../Navigation'
import {useNavigate} from 'react-router-dom'
import {UserContext} from '../UserContext'
import {listMeters, listReportTriggers} from '../Api'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import ReportTrigger from '../ReportTrigger'
import Meter from '../Meter'


type Status = 'INITIALIZED' | 'LOADING' | 'OK' | 'ERROR'

const findMeters = (meters: Meter[], meterIds: string[]) => meters.filter(m => meterIds.indexOf(m.id) !== -1)

const Overview: React.FC = () => {
  const {user} = useContext(UserContext)
  const title = navigation.reports.name
  const [status, setStatus] = useState<Status>('INITIALIZED')
  const [reports, setReports] = useState<ReportTrigger[]>([])
  const [meters, setMeters] = useState<Meter[]>([])
  const navigate = useNavigate()

  useEffect(() => {
      if (user === null) {
        return
      }
      setStatus('LOADING')
      listReportTriggers(user)
        .then(result => result
          .success(value => {
            setReports(value)
            listMeters(user)
              .then(result => result
                .success(value => {
                  setMeters(value)
                  setStatus('OK')
                })
                .error(_ => setStatus('ERROR'))
              )
          })
          .error(_ => setStatus('ERROR'))
        )
    }, [user]
  )

  let pageBody
  switch (status) {
    case 'INITIALIZED':
      pageBody = <span>Initialisiere…</span>
      break
    case 'LOADING':
      pageBody = <span>Lade…</span>
      break
    case 'OK':
      pageBody = <TableContainer>
        <Table sx={{minWidth: 650}} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Messpunkte</TableCell>
              <TableCell>Empfänger</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reports.map((report) => (
              <TableRow
                onClick={() => navigate(navigation.reportsUpdate.path, {state: {report}})}
                key={report.companyId + '-' + report.id}
                sx={{'&:last-child td, &:last-child th': {border: 0}}}
              >
                <TableCell sx={{cursor: "pointer"}} component="th" scope="row">{report.displayName}</TableCell>
                <TableCell sx={{cursor: "pointer"}} component="th"
                           scope="row">{findMeters(meters, report.meterIds).map(m => m.label).join(", ")}</TableCell>
                <TableCell sx={{cursor: "pointer"}}>{report.recipientAddress}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      break
    case 'ERROR':
      pageBody = <span>Fehler!</span>
      break
  }

  return <>
    <Breadcrumbs current={title}/>
    <Section
      pageHeader={
        <PageHeader
          title={title}
          description={'Berichte anzeigen lorem ipsum'}
          actions={[
            <Button
              variant="contained"
              onClick={() => navigate(navigation.reportsCreate.path)}
            >
              {navigation.reportsCreate.name}
            </Button>,
          ]}/>
      }
    >
      {pageBody}
    </Section>
  </>

}

export default Overview
