import {AuthenticationDetails, CognitoIdToken, CognitoUser, CognitoUserSession} from 'amazon-cognito-identity-js'
import React from 'react'
import {readConfig} from './index'
import Result from './Result'


export interface User {
  id: string
  email: string
  role: 'USER' | 'MODERATOR' | 'ADMINISTRATOR'
  salutation: 'MR' | 'MRS' | 'NEUTRAL'
  companyId: string
  name?: string
  idToken: string
}

export interface IUserContext {
  user: User | null
  setUser: SetUserFunction
}

type SetUserFunction = (user: User | null) => void

const noOpImpl: SetUserFunction = (user: User | null) => {
  console.log(JSON.stringify(user))
}

export const UserContext = React.createContext<IUserContext>({user: null, setUser: noOpImpl})

export const mapIdTokenToUser = (idToken: CognitoIdToken): User => ({
  id: idToken.payload['sub'],
  email: idToken.payload['email'],
  role: idToken.payload['role'],
  salutation: idToken.payload['salutation'],
  companyId: idToken.payload['companyId'],
  name: idToken.payload['name'],
  idToken: idToken.getJwtToken(),
})

export const authenticate = async (Username: string, Password: string) => {
  return await new Promise<User>((resolve, reject) => {
    const authentication = new AuthenticationDetails({Username, Password})
    const user = new CognitoUser({Pool: readConfig().cognito.userPool, Username})
    user.authenticateUser(authentication, {
      onSuccess: (data) => {
        try {
          resolve(mapIdTokenToUser(data.getIdToken()))
        } catch (e) {
          console.error('e1', e)
        }
      },
      onFailure: (e) => {
        reject(e)
      },/*
            newPasswordRequired: (userAttributes, requiredAttributes) => {
                console.log(userAttributes, requiredAttributes)
                resolve({userAttributes, requiredAttributes})
            }*/
    })
  })
}

export const getSession = async () => {
  return await new Promise<User | null>((resolve, reject) => {
    const user = readConfig().cognito.userPool.getCurrentUser()
    if (user === null) {
      resolve(null)
    } else {
      user.getSession((error: Error | null, session: CognitoUserSession | null) => {
        try {
          if (session !== null) {
            if (session.isValid()) {
              resolve(mapIdTokenToUser(session.getIdToken()))
            } else {
              resolve(null)
            }
            return
          }
        } catch (e) {
          console.error('e2', e)
        }
        reject(error)
      })
    }
  })
}

type GenericError = "User context error"

export const doLogout = async (): Promise<Result<void, GenericError>> => {
  const currentUser = readConfig().cognito.userPool.getCurrentUser()
  if (currentUser !== null) {
    await currentUser.signOut()
    return Result.success(undefined)
  } else {
    return Result.error("User context error")
  }
}
