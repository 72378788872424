import {grey} from '@mui/material/colors'
import {createTheme} from '@mui/material/styles'


declare module '@mui/material/styles' {
  interface Theme {
    pageHeader: {
      bgColor: string
      color: string
      borderColor: string
    }
  }

  // allow configuration using `createTheme`
  interface ThemeOptions {
    pageHeader?: {
      bgColor?: string
      color?: string
      borderColor?: string
    }
  }
}

const theme = createTheme({
  palette: {
    background: {default: grey[100]},
  },
  pageHeader: {
    bgColor: grey[200],
    color: grey[700],
    borderColor: grey[300],
  },
})

export default theme
