import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import MetaApp from './MetaApp'
import {BrowserRouter} from 'react-router-dom'
import {environment} from './environments'
import {CognitoUserPool} from 'amazon-cognito-identity-js'


export interface AppConfig {
  baseUrl: string
  cognito: { userPool: CognitoUserPool }
}

const createAppConfig = (): AppConfig => {
  const env = environment(window.location.hostname)
  console.info(`Initializing config for "${env.name}"`)

  return {
    baseUrl: env.baseUrl,
    cognito: {
      userPool: new CognitoUserPool({
        UserPoolId: env.cognito.userPoolId,
        ClientId: env.cognito.clientId,
      })
    }
  }
}

export const readConfig = (): AppConfig => {
  if (appConfig === undefined) {
    appConfig = createAppConfig()
  }
  return appConfig
}

let appConfig: AppConfig | undefined = undefined

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <MetaApp/>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)
