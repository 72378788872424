import React from 'react'
import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from '@mui/material'
import Measurand from './Measurand'


interface Props {
  measurands: Measurand[]
  setSelectedMeasurand: (measurand: string) => void
  selectedMeasurand: string
}

const MeasurandSelection: React.FC<Props> = ({measurands, setSelectedMeasurand, selectedMeasurand}) => {
  return <FormControl component="fieldset">
    <FormLabel component="legend">Messgröße</FormLabel>
    <RadioGroup
      row
      aria-label={"measurands"}
      name="controlled-radio-buttons-group"
      value={selectedMeasurand}
      onChange={(value) => setSelectedMeasurand(value.currentTarget.value)}
    >
      {measurands.map((m, index) => <FormControlLabel
        key={index}
        value={m.id}
        control={<Radio/>}
        label={m.label}/>)}
    </RadioGroup>
  </FormControl>
}

export default MeasurandSelection
