import React from 'react'
import {Typography} from '@mui/material'
import {Link} from 'react-router-dom'
import navigation from './Navigation'


const Privacy: React.FC = () => {
  return <>
    <Typography variant={"h2"}>Datenschutzerklärung von enmonitor.de</Typography>
    <Typography variant={"h3"}>Vorwort</Typography>
    <Typography variant={"body1"}>
      Wir nehmen den Schutz Ihrer privaten Daten ernst. Die besondere Beachtung der Privatsphäre bei der Verarbeitung
      persönlicher Daten ist uns ein wichtiges Anliegen. Persönliche Daten werden gemäß den Bestimmungen der
      EU-Datenschutz-Grundverordnung DSGVO und des Bundesdatenschutzgesetztes BDSG (neu) verwendet.
    </Typography>
    <Typography variant={"body1"}>
      Wir, als Betreiber dieser Webseite, verpflichten uns zur Verschwiegenheit.
    </Typography>
    <Typography variant={"h3"}>Personenbezogene Daten</Typography>
    <Typography variant={"body1"}>
      Personenbezogene Daten sind Informationen, die dazu genutzt werden können, die Identität zu erfahren. Darunter
      fallen Informationen wie
    </Typography>
    <ul>
      <li>richtiger Name</li>
      <li>Geschlecht</li>
      <li>E-Mail-Adresse</li>
      <li>Unternehmen</li>
    </ul>
    <Typography variant={"body1"}>
      Informationen, die nicht direkt mit der wirklichen Identität in Verbindung gebracht werden fallen nicht darunter,
      wie zum Beispiel
    </Typography>
    <ul>
      <li>favorisierte Webseiten</li>
      <li>Anzahl der Nutzer einer Seite</li>
    </ul>
    <Typography variant={"body1"}>
      In Verbindung mit dem Zugriff auf unsere Seiten werden serverseitig Daten (zum Beispiel IP-Adresse, Datum, Uhrzeit
      und betrachtete Seiten) gespeichert. Es findet keine personenbezogene Verwertung statt. Die statistische
      Auswertung anonymisierter Datensätze bleibt vorbehalten.
    </Typography>
    <Typography variant={"body1"}>
      Wir nutzen die persönlichen Daten zu Zwecken der technischen Administration der Webseiten, zur Erbringung unserer
      Dienstleistungen und zur Kundenverwaltung nur im jeweils dafür erforderlichen Umfang.
    </Typography>
    <Typography variant={"h3"}>Weitergabe personenbezogener Daten</Typography>
    <Typography variant={"body1"}>
      Wir verwenden personenbezogene Informationen nur für diese Webseite und geben die Informationen nicht ohne
      ausdrückliches Einverständnis an Dritte weiter.
    </Typography>
    <Typography variant={"body1"}>
      Sollten im Rahmen der Auftragsdatenverarbeitung Daten an Dienstleister weitergegeben werden, so sind diese an das
      Bundesdatenschutzgesetz BDSG, andere gesetzliche Vorschriften und an diese Privacy Policy gebunden.
    </Typography>
    <Typography variant={"body1"}>
      Erhebungen beziehungsweise Übermittlungen persönlicher Daten an staatliche Einrichtungen und Behörden erfolgen nur
      im Rahmen zwingender Rechtsvorschriften.
    </Typography>
    <Typography variant={"h3"}>Einsatz von Cookies</Typography>
    <Typography variant={"body1"}>
      Wir setzen Cookies – kleine Dateien mit Konfigurationsinformationen – ein. Dabei handelt es sich um sogenannte
      funktionale Cookies, die zwingend erforderlich sind, um wesentliche Funktionen der Webseite zu gewährleisten.
    </Typography>
    <Typography variant={"h3"}>Auskunftsrecht</Typography>
    <Typography variant={"body1"}>Sie haben jederzeit das Recht auf Auskunft über die bezüglich Ihrer Person
      gespeicherten Daten, deren Herkunft und Empfänger sowie den Zweck der Datenverarbeitung. Auskunft über die
      gespeicherten Daten gibt der <Link to={navigation.legal.path}>Webmaster</Link>.
    </Typography>
    <Typography variant={"h3"}>Recht auf Widerruf</Typography>
    <Typography variant={"body1"}>Für eine vollständige Löschung der angegebenen Daten wenden Sie sich bitte an
      den <Link to={navigation.legal.path}>Webmaster</Link>.
    </Typography>
    <Typography variant={"h3"}>Fragen und Kommentare</Typography>
    <Typography variant={"body1"}>Bei Fragen und für Anregungen und Kommentare zum Thema Datenschutz wenden Sie sich
      bitte per Mail an den <Link to={navigation.legal.path}>Webmaster</Link>.
    </Typography>
    <Typography variant={"h3"}>Sicherheitshinweis</Typography>
    <Typography variant={"body1"}>
      Wir sind bemüht Ihre personenbezogenen Daten durch Ergreifung aller technischen und organisatorischen
      Möglichkeiten so zu speichern, dass sie für Dritte nicht zugänglich sind. Bei der Kommunikation per E Mail kann
      die vollständige Datensicherheit von uns nicht gewährleistet werden, so dass wir Ihnen bei vertraulichen
      Informationen den Postweg empfehlen.
    </Typography>
    <Typography variant={"h3"}>
      Änderungen dieser Datenschutzbestimmungen
    </Typography>
    <Typography variant={"body1"}>
      Wir werden diese Richtlinien zum Schutz Ihrer persönlichen Daten von Zeit zu Zeit aktualisieren. Sie sollten sich
      diese Richtlinien gelegentlich ansehen, um auf dem Laufenden darüber zu bleiben, wie wir Ihre Daten schützen und
      die Inhalte unserer Webseite stetig verbessern. Sollten wir wesentliche Änderungen bei der Sammlung, der Nutzung
      und/oder der Weitergabe der uns von Ihnen zur Verfügung gestellten personenbezogenen Daten vornehmen, werden wir
      Sie durch einen eindeutigen und gut sichtbaren Hinweis auf der Webseite darauf aufmerksam machen. Mit der Nutzung
      der Webseite erklären Sie sich mit den Bedingungen dieser Richtlinien zum Schutz persönlicher Daten einverstanden.
    </Typography>
    <Typography variant={"h3"}>Kontakt</Typography>
    <Typography variant={"body1"}>
      Bei Fragen, Anmerkungen oder Kommentaren rund um enmonitor.de, wenden Sie sich bitte an den Webmaster. Sie finden
      die Kontaktdaten im <Link to={navigation.legal.path}>{navigation.legal.name}</Link>.
    </Typography>
  </>
}

export default Privacy
