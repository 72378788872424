import {Box, Grid, Typography} from '@mui/material'
import React, {ReactNode} from 'react'
import theme from './Theme'


type TitleSize = "xs" | "md"

interface Props {
  title: string
  description?: string
  actions?: ReactNode[]
  titleSize?: TitleSize
}

const titleSizeToTypographyVariant = (titleSize?: TitleSize) => {
  if (titleSize === undefined) {
    return "h5"
  }
  switch (titleSize) {
    case 'xs':
      return 'h6'
    case 'md':
      return 'h5'
  }
}

const PageHeader: React.FC<Props> = ({title, description, actions, titleSize, children}) => {
  return (
    <Box bgcolor={theme.pageHeader.bgColor} borderBottom={"1px solid " + theme.pageHeader.borderColor}>
      <Grid container
            direction="row"
            alignItems="center">
        <Grid item
              xs={4}
              p={2}>
          <Typography
            variant={titleSizeToTypographyVariant(titleSize)}
            color={theme.pageHeader.color}
          >
            {title}
          </Typography>
          {description !== undefined ? <Typography variant="caption" component={'p'}>{description}</Typography> : <></>}
        </Grid>
        <Grid item
              container
              xs={8}
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              columnGap={1}
              p={2}>
          {actions?.map((a, index) => <React.Fragment key={index}>{a}</React.Fragment>)}
        </Grid>
      </Grid>
      <Box>
        {children}
      </Box>
    </Box>
  )
}

export default PageHeader
