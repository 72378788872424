import * as React from 'react'
import {useContext} from 'react'
import {styled, ThemeProvider} from '@mui/material/styles'
import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import MuiAppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import CssBaseline from '@mui/material/CssBaseline'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import DrawerLink from './DrawerLink'
import {
  AccountBalance,
  Dashboard,
  ExitToApp,
  House,
  ImportExport,
  Notes,
  NotificationImportant,
  ShowChart
} from '@mui/icons-material'
import {Avatar, Chip, ListItem, ListItemIcon, ListItemText} from '@mui/material'
import {Outlet, useNavigate} from 'react-router-dom'
import {doLogout, UserContext} from './UserContext'
import theme from './Theme'
import navigation from './Navigation'


const drawerWidth = 240

const openedMixin = (theme: any) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme: any) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
})

const DrawerHeader = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(
// @ts-ignore
  ({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }))

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
// @ts-ignore
  function ({theme, open}) {
    // @ts-ignore
    return ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    })
  },
)

const App = () => {
  const [open, setOpen] = React.useState(false)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const {user} = useContext(UserContext)

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{display: 'flex'}}>
        <CssBaseline/>
        <AppBar position="fixed"
          // @ts-ignore
                open={open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: '36px',
                ...(open && {display: 'none'}),
              }}
            >
              <MenuIcon/>
            </IconButton>
            <Typography variant="h6" noWrap>
              enmonitor&nbsp;
              <Chip
                avatar={<Avatar>&beta;</Avatar>}
                label="beta"
                size={'small'}/>
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
            </IconButton>
          </DrawerHeader>
          <Divider/>
          <List>
            <DrawerLink to="/app/home" text={'Übersicht'} icon={<Dashboard/>}/>
          </List>
          <Divider/>
          <List>
            <DrawerLink to={navigation.reports.path} text={navigation.reports.name} icon={<Notes/>}/>
            <DrawerLink to={navigation.alarms.path} text={navigation.alarms.name} icon={<NotificationImportant/>}/>
            <DrawerLink to="/app/diagrams" text={'Diagramme'} icon={<ShowChart/>}/>
            <DrawerLink to="/app/costs" text={'Kosten'} icon={<AccountBalance/>}/>
            <DrawerLink to="/app/import-export" text={'Import / Export'} icon={<ImportExport/>}/>
          </List>
          <Divider/>
          {
            user?.role === "ADMINISTRATOR" ?
              <>
                <DrawerLink to={navigation.companies.path} text={navigation.companies.name} icon={<House/>}/>
                <Divider/>
              </> :
              <></>
          }
          <Logout/>

        </Drawer>
        <Box component="main" sx={{flexGrow: 1, p: 3}}>
          <DrawerHeader/>
          <Outlet/>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default App

const Logout: React.FC = () => {
  const navigate = useNavigate()
  const {setUser} = React.useContext(UserContext)
  const logout = () => {
    doLogout().then(() => {
      setUser(null)
      navigate('/login')
    })
  }
  return (
    <List>
      <ListItem button onClick={logout}>
        <ListItemIcon><ExitToApp/></ListItemIcon>
        <ListItemText>Abmelden</ListItemText>
      </ListItem>
    </List>
  )
}
