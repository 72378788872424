import React, {ReactNode} from 'react'
import {Box, Paper} from '@mui/material'


interface Props {
  pageHeader?: ReactNode
}

const Section: React.FC<Props> = ({pageHeader, children}) => {
  return (<Paper elevation={1}>
    {pageHeader}
    <Box p={2}>{children}</Box>
  </Paper>)
}

export default Section
