import React, {useContext, useEffect, useState} from 'react'
import PageHeader from '../PageHeader'
import Breadcrumbs from '../Breadcrumbs'
import Section from '../Section'
import {Button} from '@mui/material'
import navigation from '../Navigation'
import {useNavigate} from 'react-router-dom'
import {UserContext} from '../UserContext'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import {listCompanies} from '../Api'
import {Company} from '../Company'


type Status = 'INITIALIZED' | 'LOADING' | 'OK' | 'ERROR'


const Overview: React.FC = () => {
  const {user} = useContext(UserContext)
  const title = navigation.companies.name
  const [status, setStatus] = useState<Status>('INITIALIZED')
  const [companies, setCompanies] = useState<Company[]>([])
  const navigate = useNavigate()

  useEffect(() => {
      if (user === null) {
        return
      }
      setStatus('LOADING')
      listCompanies(user)
        .then(result => result
          .success(value => {
            setCompanies(value)
            setStatus('OK')
          })
          .error(_ => setStatus('ERROR'))
        )
    }, [user]
  )

  if (user?.role !== "ADMINISTRATOR") {
    return <>Forbidden! Only for admins</>
  }

  let pageBody
  switch (status) {
    case 'INITIALIZED':
      pageBody = <span>Initialisiere…</span>
      break
    case 'LOADING':
      pageBody = <span>Lade…</span>
      break
    case 'OK':
      pageBody = <TableContainer>
        <Table sx={{minWidth: 650}} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companies.map((company) => (
              <TableRow
                onClick={() => navigate(navigation.companiesUpdate.path, {state: {company}})}
                key={company.id}
                sx={{'&:last-child td, &:last-child th': {border: 0}}}
              >
                <TableCell sx={{cursor: "pointer"}} component="th" scope="row">{company.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      break
    case 'ERROR':
      pageBody = <span>Fehler!</span>
      break
  }

  return <>
    <Breadcrumbs current={title}/>
    <Section
      pageHeader={
        <PageHeader
          title={title}
          description={'Unternehmen auflisten'}
          actions={[
            <Button
              variant="contained"
              onClick={() => navigate(navigation.companiesCreate.path)}
            >
              {navigation.companiesCreate.name}
            </Button>,
          ]}/>
      }
    >
      {pageBody}
    </Section>
  </>

}

export default Overview
