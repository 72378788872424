import React, {useEffect, useState} from 'react'
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material'
import {styled} from '@mui/material/styles'
import ThresholdField from '../ThresholdField'
import AlarmTriggerDescription from './AlarmTriggerDescription'


type PeriodImplementation = "FixPeriod" | "IndividualPeriod"

type PeriodType = 'DAY' | 'WEEK' | 'MONTH' | 'QUARTER' | 'YEAR' | 'INDIVIDUAL'

type IndividualPeriodUnit = 'DAY' | 'WEEK'

interface BudgetDetails {
  periodImplementation: PeriodImplementation
  maximum: number
}

export interface FixBudgetDetails extends BudgetDetails {
  period: FixPeriod
}

export interface IndividualBudgetDetails extends BudgetDetails {
  period: IndividualPeriod
}

interface Props {
  onChange: (event: FixBudgetDetails | IndividualBudgetDetails) => void
  details: FixBudgetDetails | IndividualBudgetDetails
}

interface IndividualPeriod {
  type: IndividualPeriodUnit
  span: number
}

interface FixPeriod {
  type: PeriodType
}

interface IndividualPeriodProps {
  onChange: (period: IndividualPeriod) => void
  v: IndividualPeriod
}

interface FixPeriodProps {
  onChange: (period: FixPeriod) => void
}

const periodImplementations: { label: string, implementation: PeriodImplementation }[] = [
  {
    label: "Fester Zeitraum",
    implementation: "FixPeriod",
  },
  {
    label: "Individueller Zeitraum",
    implementation: "IndividualPeriod",
  },
]

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    'input': {
      'textAlign': 'end',
    },
  },
})


const formatISO8601Period = (period: IndividualPeriod) => `P${period.span}${period.type}`

const IndividualPeriodDisplay: React.FC<IndividualPeriodProps> = ({onChange, v}) => {
  const [period, setPeriod] = useState<IndividualPeriod>(v)
  useEffect(
    () => onChange(period),
    [onChange, period],
  )
  return <>
    <Box>
      <StyledTextField
        fullWidth
        onChange={event => setPeriod({...period, span: Number.parseInt(event.currentTarget.value)})}
        value={v?.span || 1}
        type={"number"}
        inputProps={{
          'aria-label': 'weight',
        }}
      />
    </Box>
    <ToggleButtonGroup
      color="primary"
      exclusive
      onChange={event => setPeriod({...period, type: (event.target as HTMLInputElement).value as IndividualPeriodUnit})}
      value={v?.type || "DAY"}
    >
      <ToggleButton value="DAY">Tage</ToggleButton>
      <ToggleButton value="WEEK">Wochen</ToggleButton>
    </ToggleButtonGroup>
  </>
}


const FixPeriodDisplay: React.FC<FixPeriodProps> = ({onChange}) => {
  return <FormControl component="fieldset">
    <FormLabel component="legend">Zeitraum</FormLabel>
    <RadioGroup
      row
      defaultValue="MONTH"
      name="radio-buttons-group"
      onChange={event => onChange({type: event.currentTarget.value as PeriodType})}
    >
      <FormControlLabel value="DAY" control={<Radio/>} label="Tag"/>
      <FormControlLabel value="WEEK" control={<Radio/>} label="Woche"/>
      <FormControlLabel value="MONTH" control={<Radio/>} label="Monat"/>
      <FormControlLabel value="QUARTER" control={<Radio/>} label="Quartal"/>
      <FormControlLabel value="YEAR" control={<Radio/>} label="Jahr"/>
    </RadioGroup>
  </FormControl>
}

const extractFix = (details: BudgetDetails): FixPeriod | undefined =>
  details.periodImplementation === "IndividualPeriod" ? undefined : (details as FixBudgetDetails).period

const defaultFixPeriod: FixPeriod = {
  type: "DAY"
}

const extractIndividual = (details: BudgetDetails): IndividualPeriod | undefined =>
  details.periodImplementation === "FixPeriod" ? undefined : (details as IndividualBudgetDetails).period

const defaultIndividualBudget: IndividualPeriod = {
  type: "DAY",
  span: 1,
}

const AlarmCreateBudget: React.FC<Props> = ({onChange, details}) => {
  const [periodImplementation, setPeriodImplementation] = useState(details.periodImplementation)
  const [selectedMaximum, setSelectedMaximum] = useState(details.maximum)
  const [selectedFixPeriod, setSelectedFixPeriod] = useState(extractFix(details) || defaultFixPeriod)
  const [selectedIndividualPeriod, setSelectedIndividualPeriod] = useState(extractIndividual(details) || defaultIndividualBudget)

  useEffect(() => {
    const period: FixPeriod | IndividualPeriod = periodImplementation === "FixPeriod" ?
      selectedFixPeriod :
      selectedIndividualPeriod

    const details: FixBudgetDetails | IndividualBudgetDetails = {
      period: {...period},
      maximum: selectedMaximum,
      periodImplementation: periodImplementation,
    }
    onChange(details)
  }, [selectedMaximum, selectedFixPeriod, selectedIndividualPeriod, periodImplementation, onChange])
  return <>
    <AlarmTriggerDescription
      text={"Der Budgetalarm wird ausgelöst, wenn ein definiertes Budget für einen definierten Zeitraum überschritten wird."}
      example={"10.000 kWh pro Woche"}
    />
    <Box mb={2}>
      <FormControl component="fieldset">
        <FormLabel component="legend">Art des Zeitraums</FormLabel>
        <RadioGroup
          row
          name="radio-buttons-group"
          value={periodImplementation}
        >
          {periodImplementations.map((p, index) => <FormControlLabel
            key={index}
            value={p.implementation}
            control={<Radio/>}
            onChange={e => setPeriodImplementation((e.target as HTMLInputElement).value as PeriodImplementation)}
            label={p.label}
          />)}
        </RadioGroup>
      </FormControl>
    </Box>
    <Box mb={2}>
      {
        periodImplementation === 'FixPeriod' ?
          <FixPeriodDisplay onChange={period => setSelectedFixPeriod(period)}/> :
          <IndividualPeriodDisplay
            v={selectedIndividualPeriod}
            onChange={period => setSelectedIndividualPeriod(period)}
          />
      }
    </Box>
    <Box>
      <ThresholdField
        value={selectedMaximum}
        label={"Schwellwert"}
        onChange={value => setSelectedMaximum(value)}
      />
    </Box>
  </>
}

export default AlarmCreateBudget
