import React, {useEffect, useState} from 'react'
import {Autocomplete, Box, Grid, TextField} from '@mui/material'
import Timezone from '../Timezone'


export interface Common {
  name: string
  timezone: string
  recipients: string[]
}

interface Props {
  name?: string
  timezone?: string
  recipients?: string[]
  onChange: (common: Common) => void
}

const defaultNameValue: string = 'Neuer Alarm'
const timezones: Timezone[] = [{label: 'Europa/Berlin', id: 'Europe/Berlin'}]
const defaultTimezone = timezones[0]

const AlarmCreateCommon: React.FC<Props> = ({name, timezone, recipients, onChange}) => {
  const [selectedName, setSelectedName] = useState<string>(name || defaultNameValue)
  const [selectedTimezone, setSelectedTimezone] = useState<Timezone>(timezones.find(t => t.id === timezone) || defaultTimezone)
  const [selectedRecipients, setSelectedRecipients] = useState<string>(recipients?.join(", ") || "")
  useEffect(() => {
    onChange({
      name: selectedName,
      timezone: selectedTimezone.id,
      recipients: selectedRecipients.split(",").map(r => r.trim()).filter(r => r !== "")
    })
  }, [selectedName, selectedTimezone, selectedRecipients, onChange])

  return (<Grid container>
    <Grid item xs={8}>
      <TextField
        label="Name"
        placeholder="Bitte geben Sie einen Namen ein"
        value={selectedName}
        onChange={event => setSelectedName(event.currentTarget.value)}
        fullWidth
        focused
        required
        sx={{mb: 2}}
      />

      <TextField
        label={'Empfänger'}
        placeholder="Bitte geben Sie E-Mail-Adresse(n) ein"
        value={selectedRecipients}
        onChange={event => setSelectedRecipients(event.currentTarget.value)}
        type={'email'}
        fullWidth
        required
        sx={{mb: 2}}
      />

      <Autocomplete
        id="timezone-select"
        onChange={(event, value) => {
          setSelectedTimezone(value!)
        }}
        value={selectedTimezone}
        options={timezones}
        autoHighlight
        fullWidth
        getOptionLabel={(option) => option.label}
        renderOption={(props, option) => (
          <Box
            component="li"
            {...props}
          >
            {option.label}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            label="Zeitzone auswählen"
            inputProps={{
              ...params.inputProps,
              // disable autocomplete and autofill
              autoComplete: 'new-password',
            }}
          />
        )}
      />
    </Grid>
  </Grid>)
}

export default AlarmCreateCommon
