import React, {useEffect, useState} from 'react'
import {Grid} from "@mui/material"
import Measurand from '../Measurand'
import ThresholdField from '../ThresholdField'
import MeasurandSelection from '../MeasurandSelection'
import AlarmTriggerDescription from './AlarmTriggerDescription'


export interface SchwellwertDetails {
  measurand: string
  lowerLimit: number
  upperLimit: number
}

interface Props {
  measurand?: string
  lowerLimit?: number
  upperLimit?: number
  onChange: (details: SchwellwertDetails) => void
}

const measurands: Measurand[] = [
  {id: 'READING', label: 'Zählerstand (kWh)'},
  {id: 'ELECTRICAL_CONSUMPTION', label: 'Verbrauch (kWh)'},
  {id: 'ELECTRICAL_POWER', label: 'Leistung (kW)'},
]

const AlarmCreateSchwellwert: React.FC<Props> = ({measurand, lowerLimit, upperLimit, onChange}) => {
  const [selectedMeasurand, setSelectedMeasurand] = useState<string>(measurand || measurands[0].id)
  const [lowerSelectedThreshold, setLowerSelectedThreshold] = useState<number>(lowerLimit || 0)
  const [upperSelectedThreshold, setUpperSelectedThreshold] = useState<number>(upperLimit || 0)
  useEffect(() => {
    onChange({
      measurand: selectedMeasurand,
      lowerLimit: lowerSelectedThreshold,
      upperLimit: upperSelectedThreshold
    })
  }, [selectedMeasurand, lowerSelectedThreshold, upperSelectedThreshold, onChange])


  return <>
    <AlarmTriggerDescription
      text={"Die Schwellwertkontrolle prüft, ob ein unter Grenzwert unterschritten und/oder ein maximaler Grenzwert überschritten wurde"}
    />
    <Grid container direction={'row'}>
      <Grid item xs={4}>
        <MeasurandSelection
          measurands={measurands}
          setSelectedMeasurand={setSelectedMeasurand}
          selectedMeasurand={selectedMeasurand}
        />
      </Grid>
      <Grid item xs={4}>
        <ThresholdField
          value={lowerSelectedThreshold}
          label={'Untere Grenze'}
          onChange={v => setLowerSelectedThreshold(v)}
        />
      </Grid>
      <Grid item xs={4}>
        <ThresholdField
          value={upperSelectedThreshold}
          label={'Obere Grenze'}
          onChange={v => setUpperSelectedThreshold(v)}
        />
      </Grid>
    </Grid>
  </>
}

export default AlarmCreateSchwellwert
