export type AlarmType =
  'AusreisserTrigger'
  | 'BudgetTrigger'
  | 'PlausibilitaetTrigger'
  | 'SchwellwertTrigger'
  | 'MesswertkontrolleTrigger'


export const mapTypesToNames = (name: AlarmType): string => {
  switch (name) {
    case 'AusreisserTrigger':
      return 'Ausreißer'
    case 'BudgetTrigger':
      return 'Budget'
    case 'SchwellwertTrigger':
      return 'Schwellwert'
    case 'PlausibilitaetTrigger':
      return 'Plausibilität'
    case "MesswertkontrolleTrigger":
      return "Messwertkontrolle"
  }
  console.warn(`No name mapping available for ${name}!`)
  return name
}
