import React, {createContext, useContext, useEffect, useState} from 'react'
import {Card, CardContent, Grid, Typography} from '@mui/material'
import theme from './Theme'
import {AlarmType} from './alarms/AlarmType'


interface AdvancedRadioGroupProps {
  defaultValue: string
  value: string | undefined
  onChange: (value: string) => void
}

interface AdvancedRadioGroupContext {
  selection: string
  setSelection: (selection: string) => void
}

// @ts-ignore
const context = createContext<AdvancedRadioGroupContext>()

export const AdvancedRadioGroup: React.FC<AdvancedRadioGroupProps> = ({defaultValue, value, onChange, children}) => {
  const [selection, setSelection] = useState(value || defaultValue)
  useEffect(() => {
    onChange(selection)
  }, [selection, onChange])

  const myContext: AdvancedRadioGroupContext = {selection, setSelection}
  return (
    <context.Provider value={myContext}>
      <Grid
        container
        spacing={2}
      >
        {children}
      </Grid>
    </context.Provider>
  )
}

interface AdvancedRadioProps {
  value: AlarmType
  title: string
  description: string
}

const boxTheme = {
  selected: {
    borderColor: theme.palette.primary.dark,
    bgColor: theme.palette.grey[200],
    //color: theme.palette.primary.contrastText,
    boxShadow: '0 2px 5px rgba(0,0,0,0.25)',
  },
  unselected: {
    borderColor: '#c0c0c0',
    bgColor: theme.palette.grey[100],
    //color: '#000000',
    boxShadow: 'none'
  }
}

export const AdvancedRadio: React.FC<AdvancedRadioProps> = ({value, title, description}) => {
  const {selection, setSelection} = useContext(context)
  const selected = value === selection
  const selectedTheme = selected ? boxTheme.selected : boxTheme.unselected

  return <Grid
    item
    xs
  >
    <Card
      onClick={() => setSelection(value)}
      elevation={selected ? 5 : 1}
      sx={{
        bgcolor: selectedTheme.bgColor,
        cursor: 'pointer',
      }}
    >
      <CardContent sx={{
        minHeight: '150px',
      }}>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
        >
          {title}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
        >
          {description}
        </Typography>
      </CardContent>
    </Card>
  </Grid>
}
