import React, {useEffect, useState} from 'react'
import {Grid} from '@mui/material'
import Measurand from '../Measurand'
import MeterAutocomplete from '../MeterAutocomplete'
import Meter from '../Meter'
import MeasurandSelection from '../MeasurandSelection'
import ThresholdField from '../ThresholdField'
import AlarmTriggerDescription from './AlarmTriggerDescription'


export interface PlausibiliteatDetails {
  measurand: string
  threshold: number
  meterIdToCompareWith?: string
}

interface Props {
  meters: Meter[]
  measurand?: string
  threshold?: number
  meterIdToCompareWith?: string
  onChange: (details: PlausibiliteatDetails) => void
}

const measurands: Measurand[] = [
  {id: 'READING', label: 'Zählerstand (kWh)'},
  {id: 'ELECTRICAL_CONSUMPTION', label: 'Verbrauch (kWh)'},
  {id: 'ELECTRICAL_POWER', label: 'Leistung (kW)'},
]

const AlarmCreatePlausibilitaet: React.FC<Props> = ({meters, measurand, threshold, meterIdToCompareWith, onChange}) => {
  const [selectedMeasurand, setSelectedMeasurand] = useState<string>(measurand || measurands[0].id)
  const [selectedThreshold, setSelectedThreshold] = useState<number>(threshold || 1)
  const [selectedMeterIdToCompareWith, setSelectedMeterIdToCompareWith] = useState<string | undefined>(meterIdToCompareWith)
  useEffect(() => {
    onChange({
      measurand: selectedMeasurand,
      threshold: selectedThreshold,
      meterIdToCompareWith: selectedMeterIdToCompareWith
    })
  }, [selectedMeasurand, selectedThreshold, selectedMeterIdToCompareWith, onChange])

  return <>
    <AlarmTriggerDescription
      text={"Ein Alarm wird ausgelöst, wenn die Differenz zwischen den Messwerten zweier Messpunkte zum selben Zeitpunkt größer als der definierte Wert ist"}
      example={"Abweichung 10%; Messpunkt Am 17:00 Uhr, 100 kWh; Messpunkt B, 17:00 Uhr, 120 kWh => Abweichung 20% => Alarm"}
    />
    <Grid container direction={'column'}>
      <Grid>
        <MeasurandSelection
          measurands={measurands}
          selectedMeasurand={selectedMeasurand}
          setSelectedMeasurand={setSelectedMeasurand}
        />
      </Grid>
      <Grid>
        <ThresholdField
          label={"Schwellwert"}
          value={selectedThreshold}
          onChange={value => setSelectedThreshold(value)}
        />
      </Grid>
      <Grid>
        <MeterAutocomplete
          id={"meter-autocomplete"}
          label={"Vergleichsmesspunkt auswählen"}
          onChange={value => setSelectedMeterIdToCompareWith(value?.id)}
          values={meters}
          value={meters.find(m => m.id === selectedMeterIdToCompareWith) || null}
        />
      </Grid>
    </Grid>
  </>
}

export default AlarmCreatePlausibilitaet
