import React, {ReactNode, useContext, useEffect, useState} from 'react'
import PageHeader from '../PageHeader'
import Section from '../Section'
import {Button, Grid, Step, StepContent, StepLabel, Stepper, Typography} from '@mui/material'
import Breadcrumbs from '../Breadcrumbs'
import {User, UserContext} from '../UserContext'
import {AdvancedRadio, AdvancedRadioGroup} from '../AdvancedRadio'
import {useLocation, useNavigate} from 'react-router-dom'
import AlarmCreateAusreisser, {AusreisserDetails} from './AlarmCreateAusreisser'
import AlarmCreateCommon, {Common} from './AlarmCreateCommon'
import navigation from '../Navigation'
import {LoadingButton} from '@mui/lab'
import {createAlarmTrigger, deleteAlarmTrigger, listMeters} from '../Api'
import AlarmCreatePlausibilitaet, {PlausibiliteatDetails} from './AlarmCreatePlausibilitaet'
import AlarmCreateSchwellwert, {SchwellwertDetails} from './AlarmCreateSchwellwert'
import Meter from '../Meter'
import MeterAutocomplete from '../MeterAutocomplete'
import AlarmCreateBudget, {FixBudgetDetails, IndividualBudgetDetails} from './AlarmCreateBudget'
import AlarmCreateMesswertKontrolle, {MesswertkontrolleDetails} from './AlarmCreateMesswertKontrolle'
import {AlarmType} from './AlarmType'


const title = (meterId: string | undefined, uuid: string | undefined) =>
  meterId !== undefined && uuid !== undefined ?
    "Alarm aktualisieren" :
    'Alarm erstellen'

interface HeaderProps {
  meterId: string | undefined
  uuid: string | undefined
  user: User | null
}

const SpecialPageHeader: React.FC<HeaderProps> = ({meterId, uuid, user}) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const isUpdate = meterId !== undefined && uuid !== undefined
  const description = isUpdate ?
    'Aktualisieren Sie einen Alarm' :
    'Erstellen Sie einen neuen Alarm'
  const handleDelete = () => {
    if (user === null || meterId === undefined || uuid === undefined) {
      return
    }
    setLoading(true)
    deleteAlarmTrigger(user, meterId, uuid)
      .then(result => result
        .success(_ => {
            setLoading(false)
            navigate(navigation.alarms.path)
          }
        )
        .error(value => {
          setLoading(false)
          console.error(value)
        })
      )
  }
  const actions = []
  if (isUpdate) {
    actions.push(
      <LoadingButton
        loading={loading}
        variant="outlined"
        onClick={handleDelete}
      >
        Alarm löschen
      </LoadingButton>
    )
  }
  return <PageHeader
    title={title(meterId, uuid)}
    description={description}
    actions={actions}
  />
}

const defaultBudgetDetails: FixBudgetDetails = {
  period: {type: "DAY"},
  maximum: 1,
  periodImplementation: "FixPeriod",
}

const extractAusreisserDetails = (alarmToUpdate: AusreisserDetails | undefined): AusreisserDetails | undefined => {
  if (alarmToUpdate === undefined) {
    return undefined
  }
  const {threshold} = alarmToUpdate
  if (threshold !== undefined) {
    return {threshold}
  }
  return undefined
}

const extractPlausibititaetDetails = (alarmToUpdate: PlausibiliteatDetails | undefined): PlausibiliteatDetails | undefined => {
  if (alarmToUpdate === undefined) {
    return undefined
  }
  const {threshold, meterIdToCompareWith, measurand} = alarmToUpdate
  if (threshold !== undefined && meterIdToCompareWith !== undefined && measurand !== undefined) {
    return {threshold, meterIdToCompareWith, measurand}
  }
  return undefined
}

const extractSchwellwertDetails = (alarmToUpdate: SchwellwertDetails | undefined): SchwellwertDetails | undefined => {
  if (alarmToUpdate === undefined) {
    return undefined
  }
  const {measurand, lowerLimit, upperLimit} = alarmToUpdate
  if (measurand !== undefined && lowerLimit !== undefined && upperLimit !== undefined) {
    return {measurand, lowerLimit, upperLimit}
  }
  return undefined
}

const extractMesswertkontrolleDetails = (alarmToUpdate: MesswertkontrolleDetails | undefined): MesswertkontrolleDetails | undefined => {
  if (alarmToUpdate === undefined) {
    return undefined
  }
  const {interval} = alarmToUpdate
  if (interval !== undefined) {
    return {interval}
  }
  return undefined
}
const extractBudgetDetails = (alarmToUpdate: FixBudgetDetails | IndividualBudgetDetails | undefined): FixBudgetDetails | IndividualBudgetDetails | undefined => {
  if (alarmToUpdate === undefined) {
    return undefined
  }
  const {periodImplementation, maximum, period} = alarmToUpdate
  if (periodImplementation !== undefined && maximum !== undefined && period !== undefined) {
    return {periodImplementation, maximum, period}
  }
  return undefined
}

const extractCommon = (alarmToUpdate: any): Common | undefined => {
  if (alarmToUpdate === undefined) {
    return undefined
  }
  const {name, zone, recipients} = alarmToUpdate
  if (name !== undefined && zone !== undefined && recipients !== undefined) {
    return {name, timezone: zone, recipients}
  }
  return undefined
}

const AlarmCreate: React.FC = () => {
  const location = useLocation()
  const alarmToUpdate = location.state?.alarm
  const {user} = useContext(UserContext)
  const uuid = alarmToUpdate?.uuid
  const defaultValue: AlarmType = alarmToUpdate?.type || 'AusreisserTrigger'
  const [trigger, setTrigger] = useState<AlarmType>(defaultValue)
  const [loading, setLoading] = useState(false)
  const [meters, setMeters] = useState<Meter[]>([])
  const [meterId, setMeterId] = useState<string | undefined>(alarmToUpdate?.meterId)
  const [activeStep, setActiveStep] = useState(0)
  const [common, setCommon] = useState<Common | undefined>(extractCommon(alarmToUpdate))
  const [alarm, setAlarm] = useState<{}>()
  const [ausreisserDetails, setAusreisserDetails] = useState<AusreisserDetails | undefined>(extractAusreisserDetails(alarmToUpdate))
  const [plausibilitaetDetails, setPlausibilitaetDetails] = useState<PlausibiliteatDetails | undefined>(extractPlausibititaetDetails(alarmToUpdate))
  const [schwellwertDetails, setSchwellwertDetails] = useState<SchwellwertDetails | undefined>(extractSchwellwertDetails(alarmToUpdate))
  const [messwertkontrolleDetails, setMesswertkontrolleDetails] = useState<MesswertkontrolleDetails | undefined>(extractMesswertkontrolleDetails(alarmToUpdate))
  const [budgetDetails, setBudgetDetails] = useState<FixBudgetDetails | IndividualBudgetDetails>(extractBudgetDetails(alarmToUpdate) || defaultBudgetDetails)

  useEffect(() => {
    let details: AusreisserDetails |
      PlausibiliteatDetails |
      SchwellwertDetails |
      FixBudgetDetails | IndividualBudgetDetails |
      MesswertkontrolleDetails |
      undefined = undefined
    switch (trigger) {
      case "AusreisserTrigger":
        details = ausreisserDetails
        break
      case 'BudgetTrigger':
        details = budgetDetails
        break
      case 'PlausibilitaetTrigger':
        details = plausibilitaetDetails
        break
      case 'SchwellwertTrigger':
        details = schwellwertDetails
        break
      case 'MesswertkontrolleTrigger':
        details = messwertkontrolleDetails
        break
      default:
        details = undefined
        break
    }
    setAlarm(_ => ({...common, type: trigger, ...details, meterId, uuid}))
  }, [
    common,
    setAlarm,
    trigger,
    ausreisserDetails,
    budgetDetails,
    plausibilitaetDetails,
    schwellwertDetails,
    messwertkontrolleDetails,
    meterId,
    uuid,
  ])
  useEffect(() => {
    if (user === null) {
      return
    }
    listMeters(user)
      .then(result => result
        .success(value => setMeters(value))
        .error(value => console.error(value))
      )
  }, [user])

  const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1)
  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1)
  const navigate = useNavigate()
  const cancel = () => navigate(navigation.alarms.path)
  let triggerStep: ReactNode = <></>
  switch (trigger) {
    case 'AusreisserTrigger':
      triggerStep = <AlarmCreateAusreisser
        threshold={ausreisserDetails?.threshold}
        onChange={setAusreisserDetails}/>
      break
    case 'BudgetTrigger':
      triggerStep = <AlarmCreateBudget
        onChange={setBudgetDetails}
        details={budgetDetails}
      />
      break
    case 'PlausibilitaetTrigger':
      triggerStep = <AlarmCreatePlausibilitaet
        meters={meters}
        measurand={plausibilitaetDetails?.measurand}
        threshold={plausibilitaetDetails?.threshold}
        meterIdToCompareWith={plausibilitaetDetails?.meterIdToCompareWith}
        onChange={setPlausibilitaetDetails}
      />
      break
    case 'SchwellwertTrigger':
      triggerStep = <AlarmCreateSchwellwert
        measurand={schwellwertDetails?.measurand}
        lowerLimit={schwellwertDetails?.lowerLimit}
        upperLimit={schwellwertDetails?.upperLimit}
        onChange={setSchwellwertDetails}
      />
      break
    case "MesswertkontrolleTrigger":
      triggerStep = <AlarmCreateMesswertKontrolle
        onChange={setMesswertkontrolleDetails}
        interval={messwertkontrolleDetails?.interval}
      />
      break
  }
  const isFirstStep = activeStep === 0
  const isLastStep = activeStep === 4

  const create = () => {
    if (user === null) {
      return
    }
    setLoading(true)
    createAlarmTrigger(user, alarm, trigger)
      .then(response => {
        response
          .success(() => navigate(navigation.alarms.path))
          .error(e => {
            console.error(e)
            setLoading(false)
          })
      })
  }

  return <>
    <Breadcrumbs previous={[navigation.alarms]} current={title(meterId, uuid)}/>
    <Section pageHeader={<SpecialPageHeader meterId={meterId} uuid={uuid} user={user}/>}>
      <Typography
        mb={4}
        variant={'h6'}
      >
        Bei dieser Funktion handelt es sich um eine Vorschau, sie ist deshalb noch nicht
        komplett funktionsfähig.
      </Typography>
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step>
          <StepLabel>Allgemeine Konfiguration</StepLabel>
          <StepContent>
            <AlarmCreateCommon
              onChange={setCommon}
              name={common?.name}
              timezone={common?.timezone}
              recipients={common?.recipients}
            />
          </StepContent>
        </Step>

        <Step>
          <StepLabel>Messpunkt auswählen</StepLabel>
          <StepContent>
            <MeterAutocomplete
              id={"meter-autocomplete"}
              label={"Messpunkt auswählen"}
              values={meters}
              value={meters.find(m => m.id === meterId) || null}
              onChange={(value) => setMeterId(value?.id)}
            />
          </StepContent>
        </Step>

        <Step>
          <StepLabel>Wählen Sie einen Auslösertypen aus</StepLabel>
          <StepContent>
            <AdvancedRadioGroup
              defaultValue={defaultValue}
              value={trigger}
              onChange={(v) => {
                setTrigger(prevState => {
                  prevState = v as AlarmType
                  return prevState
                })
              }}>
              <AdvancedRadio title="Ausreißer"
                             value={'AusreisserTrigger'}
                             description="Erkennt Ausreißer in aufeinander folgenden Messwerten"/>
              <AdvancedRadio title="Budget"
                             value={'BudgetTrigger'}
                             description="Erkennt Überschreitungen eines angegebenen Budgets in kWh"/>
              <AdvancedRadio title="Plausibilität"
                             value={'PlausibilitaetTrigger'}
                             description="Erkennt Unterschiede zwischen Messpunkten"/>
              <AdvancedRadio title="Schwellwert"
                             value={'SchwellwertTrigger'}
                             description="Erkennt Über- oder Unterschreitungen eines definierten Schwellwertes"/>
              <AdvancedRadio title="Messwertkontrolle"
                             value={'MesswertkontrolleTrigger'}
                             description="Erkennt Über- oder Unterschreitungen eines definierten Schwellwertes"/>
            </AdvancedRadioGroup>
          </StepContent>
        </Step>

        <Step>
          <StepLabel>Auslöserdetails</StepLabel>
          <StepContent>{triggerStep}</StepContent>
        </Step>

        <Step>
          <StepLabel>Zusammenfassung</StepLabel>
          <StepContent>
            <pre>{JSON.stringify(alarm, null, 2)}</pre>
          </StepContent>
        </Step>
      </Stepper>

      <Grid container
            direction="row"
            justifyContent="flex-end"
            alignItems="center">
        <Button variant={'outlined'}
                sx={{mr: 1}}
                onClick={isFirstStep ? cancel : handleBack}>{isFirstStep ? 'Abbrechen' : 'Zurück'}</Button>
        <LoadingButton
          loading={loading}
          variant={'contained'}
          onClick={isLastStep ? create : handleNext}
        >
          {isLastStep ? (alarmToUpdate === undefined ? 'Auslöser erstellen' : "Alarm aktualisieren") : 'Weiter'}
        </LoadingButton>
      </Grid>
    </Section>
  </>
}

export default AlarmCreate
