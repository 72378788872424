import React, {useEffect, useState} from 'react'
import AlarmTriggerDescription from './AlarmTriggerDescription'
import {MenuItem, Select, SelectChangeEvent, Typography} from '@mui/material'
import {DateTime, DateTimeFormatOptions, Duration} from "luxon"


export interface MesswertkontrolleDetails {
  interval: number
}

interface Props {
  interval?: number
  onChange: (details: MesswertkontrolleDetails) => void
}

interface Interval {
  value: number
  label: string
}

const values: Interval[] = [
  {value: 1, label: "stündlich"},
  {value: 2, label: "zweistündlich"},
  {value: 3, label: "dreistündlich"},
  {value: 4, label: "vierstündlich"},
  {value: 6, label: "sechsstündlich"},
  {value: 12, label: "halbtägig"},
  {value: 24, label: "täglich"},
  {value: 36, label: "eineinhalbtägig"},
]

const defaultValue: Interval = values[0]

const preview = (date: DateTime, interval: number): DateTime[] => {
  const duration = Duration.fromISO(`PT${interval}H`)
  const t = date.toSeconds()
  const hoursSinceEpoch = Math.floor(t / 60 / 60)
  const differenceToLastRun = hoursSinceEpoch % interval
  let nextDate = DateTime.fromSeconds((hoursSinceEpoch - differenceToLastRun) * 60 * 60, {zone: "UTC"})
  const previews: DateTime[] = []
  while (previews.length < 10) {
    nextDate = nextDate.plus(duration)
    previews.push(nextDate)
  }
  return previews
}

const formatOpts: DateTimeFormatOptions = {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
  hour: "2-digit",
  minute: "2-digit",
  timeZoneName: "short",
}

const AlarmCreateMesswertKontrolle: React.FC<Props> = ({interval, onChange}) => {
  const date = DateTime.utc()
  const [selectedInterval, setSelectedInterval] = useState<string>((interval || defaultValue.value) + "")
  const handleChange = (event: SelectChangeEvent) => setSelectedInterval(event.target.value)
  useEffect(() => {
    onChange({
      interval: Number.parseInt(selectedInterval)
    })
  }, [selectedInterval, onChange])

  return <>
    <AlarmTriggerDescription
      text={"Die Messwertkontrolle..."}
      example={"..."}
    />
    <Select
      id={"interval"}
      onChange={handleChange}
      defaultValue={defaultValue.label}
      value={selectedInterval}
      sx={{mb: 2}}
    >
      {values.map((v, index) => <MenuItem key={index} value={v.value}>{v.label}</MenuItem>)}
    </Select>

    <Typography
      variant="body2"
      color="text.secondary"
      component={"div"}
    >
      <div>Die nächsten 10 Ausführungen</div>
      {
        preview(date, Number.parseInt(selectedInterval))
          .map(d => d.toLocaleString(formatOpts))
          .map((d, index) => <div key={index}>{d}</div>)
      }
    </Typography>
  </>
}

export default AlarmCreateMesswertKontrolle
