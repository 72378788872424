import {ListItem, ListItemIcon, ListItemText} from '@mui/material'
import React from 'react'
import {useNavigate} from 'react-router-dom'


interface Props {
    to: string
    text: string
    icon: React.ReactElement
}

const DrawerLink: React.FC<Props> = ({to, text, icon}) => {
    const navigate = useNavigate()

    return <ListItem button onClick={() => navigate(to)}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText>{text}</ListItemText>
    </ListItem>
}

export default DrawerLink
