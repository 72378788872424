import React, {FormEvent, useContext, useEffect, useState} from 'react'
import navigation from '../Navigation'
import Breadcrumbs from '../Breadcrumbs'
import PageHeader from '../PageHeader'
import Section from '../Section'
import {Autocomplete, FormControl, Grid, InputLabel, MenuItem, Select, TextField} from '@mui/material'
import Meter from '../Meter'
import {createReportTrigger, deleteReportTrigger, listMeters} from '../Api'
import {UserContext} from '../UserContext'
import {useLocation, useNavigate} from 'react-router-dom'
import ReportTrigger from '../ReportTrigger'
import {LoadingButton} from '@mui/lab'


const title = (isEdit: boolean): string => isEdit ?
  navigation.reportsUpdate.name :
  navigation.reportsCreate.name

type Loading = "NONE" | "UPDATE" | "DELETE"

const Edit: React.FC = () => {
  // data from state
  const location = useLocation()

  const navigate = useNavigate()

  const {user} = useContext(UserContext)

  // data from api
  const [meterOptions, setMeterOptions] = useState<Meter[]>([])
  const [isEdit, setIsEdit] = useState<boolean>(false)

  // form values
  const [id, setId] = useState<string | undefined>()
  const [name, setName] = useState("Unbenannter Bericht")
  const [companyId, setCompanyId] = useState<string | undefined>()
  const [recipient, setRecipient] = useState("")
  const [recurrence, setRecurrence] = useState("DAILY")
  const [meters, setMeters] = useState<Meter[]>([])

  const [loading, setLoading] = useState<Loading>("NONE")

  const onSubmit = (event: FormEvent) => {
    if (user === null) {
      return
    }
    event.preventDefault()
    setLoading("UPDATE")
    if (meters.length === 0) {
      alert("Bitte wählen Sie mindestens einen Messpunkt aus!")
    }

    createReportTrigger(user, {
      id,
      displayName: name,
      recipientAddress: recipient,
      meterIds: meters.map(m => m.id),
      recurrence,
      companyId
    })
      .then(response => {
        response
          .success(() => navigate(navigation.reports.path))
          .error(e => {
            console.error(e)
            setLoading("NONE")
          })
      })
  }

  useEffect(() => {
      if (location?.state?.report === undefined || location?.state?.report === null) {
        return
      }
      const report: ReportTrigger = location.state.report
      setIsEdit(true)
      setId(report.id)
      setCompanyId(report.companyId)
      setName(report.displayName)
      setName(report.displayName)
      setRecurrence(report.recurrence)
    },
    [location]
  )

  useEffect(() => {
      if (location?.state?.report === undefined || location?.state?.report === null) {
        return
      }
      setMeters(meterOptions.filter(m => location.state.report.meterIds.indexOf(m.id) !== -1))
    },
    [location, meterOptions]
  )

  useEffect(() => {
      if ((location?.state?.report === undefined || location?.state?.report === null) && user === null) {
        return
      }
      setRecipient(location?.state?.report?.recipientAddress || user?.email)
    },
    [location, user]
  )

  useEffect(() => {
    if (user === null) {
      return
    }
    listMeters(user)
      .then(result => result
        .success(value => setMeterOptions(value))
        .error(value => console.error(value))
      )
  }, [user])

  const handleDelete = () => {
    if (user === null || companyId === undefined || id === undefined) {
      return
    }
    setLoading("DELETE")
    deleteReportTrigger(user, companyId, id)
      .then(result => result
        .success(_ => {
            setLoading("NONE")
            navigate(navigation.reports.path)
          }
        )
        .error(value => {
          setLoading("NONE")
          console.error(value)
        })
      )
  }

  return (<>
    <Breadcrumbs previous={[navigation.reports]} current={title(isEdit)}/>
    <form onSubmit={onSubmit}>
      <Grid container>
        <Grid item xs={8}>
          <Section
            pageHeader={
              <PageHeader
                title={'Allgemeine Informationen'}
                titleSize={"xs"}
              />
            }>
            <TextField
              required
              label={"Name"}
              name={"name"}
              fullWidth
              value={name}
              onChange={e => setName(e.currentTarget.value)}
            />

            <TextField
              required
              label={"E-Mail-Adresse des Empfängers"}
              name={"recipient"}
              fullWidth
              value={recipient}
              onChange={e => setRecipient(e.currentTarget.value)}
              sx={{mt: 2}}
            />

            <FormControl
              fullWidth
              sx={{mt: 2}}
            >
              <InputLabel id="recurrence-select">Wiederholung</InputLabel>
              <Select
                labelId="recurrence-select"
                id="recurrence-select"
                value={recurrence}
                label="Wiederholung"
                onChange={e => setRecurrence(e.target.value)}
              >
                <MenuItem value={'DAILY'}>Täglich</MenuItem>
                <MenuItem value={'WEEKLY'}>Wöchentlich</MenuItem>
                <MenuItem value={'MONTHLY'}>Monatlich</MenuItem>
              </Select>
            </FormControl>

            <Autocomplete
              multiple
              sx={{mt: 2}}
              id="meters-autocomplete"
              value={meters}
              options={meterOptions}
              getOptionLabel={(option: Meter) => option.label}
              isOptionEqualToValue={(option, value1) => option.id === value1.id}
              onChange={(event, value) => setMeters(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Messpunktauswahl"
                  placeholder="Messpunkte"
                />
              )}
            />
          </Section>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            mt={2}
          >
            <Grid item>
              {
                isEdit ?
                  <LoadingButton
                    loading={loading === "DELETE"}
                    disabled={loading !== "NONE"}
                    variant="outlined"
                    onClick={handleDelete}
                    sx={{mr: 2}}
                  >
                    Löschen
                  </LoadingButton> :
                  <></>
              }
              <LoadingButton
                loading={loading === "UPDATE"}
                disabled={loading !== "NONE"}
                variant={'contained'}
                type={"submit"}>
                Speichern
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  </>)
}

export default Edit
