import {Box, Breadcrumbs as MuiBreadcrumbs, Typography} from '@mui/material'
import {Link} from 'react-router-dom'
import React from 'react'
import {NavigationItem} from './Navigation'


interface Props {
  previous?: NavigationItem[]
  current: string
}

const Breadcrumbs: React.FC<Props> = ({previous, current}) => {
  const prev = [{path: '/app/home', name: 'enmonitor'}, ...(previous || [])]
  return <Box my={2}>
    <MuiBreadcrumbs aria-label="breadcrumb">
      {
        prev.map(p => <Link key={p.path}
                            to={p.path}>
            <Typography color="text.secondary">
              {p.name}
            </Typography>
          </Link>
        )
      }
      <Typography color="text.primary">{current}</Typography>
    </MuiBreadcrumbs>
  </Box>
}

export default Breadcrumbs
