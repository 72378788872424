import React from 'react'
import {TextField} from '@mui/material'


interface Props {
  label: string
  value: number
  onChange: (value: number) => void
}

const ThresholdField: React.FC<Props> = ({label, value, onChange}) =>
  <TextField
    fullWidth
    value={value}
    label={label}
    onChange={event => {
      const value = event.currentTarget.value
      if (value.trim().length > 0) {
        onChange(Number.parseInt(value))
      }
    }}
  />

export default ThresholdField
