import {authenticate, UserContext} from "./UserContext"
import React from "react"
import {useLocation, useNavigate} from "react-router-dom"
import {LoadingButton} from '@mui/lab'
import {Button, Grid, TextField, Typography} from '@mui/material'
import backgroundImage from "./background-medium.jpg"
import navigation from './Navigation'


interface CognitoError {
  name: "UserNotFoundException" | "NotAuthorizedException"
}

const Login: React.FC = () => {
  const [email, setEmail] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [password, setPassword] = React.useState('')
  const [error, setError] = React.useState<string | null>(null)
  const {setUser} = React.useContext(UserContext)
  const navigate = useNavigate()
  const location = useLocation()

  const onSubmit = (event: any) => {
    setLoading(true)
    event.preventDefault()
    authenticate(email, password)
      .then((u) => {
        setUser(u)
        const path = location.state?.previousPath || "/app"
        navigate(path)
      })
      .catch((e) => {
        const error = e as CognitoError
        switch (error.name) {
          case "UserNotFoundException":
            setError("not found")
            break
          case "NotAuthorizedException":
            setError("not authorized")
            break
          default:
            console.error('Failed', e)
            break
        }
        setLoading(false)
      })
  }

  return (<Grid container sx={{height: "100vh"}}>
    <Grid item xs={6} container>
      <Grid item sx={{mt: '30vh', p: 4}} xs={12}>
        <Typography variant={"h2"}>enmonitor</Typography>
        <p>{error}</p>
        <form onSubmit={onSubmit}>
          <TextField
            label={"E-Mail-Adresse"}
            value={email}
            sx={{mb: 2}}
            fullWidth
            onChange={(event) => setEmail(event.target.value)}/>
          <br/>

          <TextField
            label={"Password"}
            value={password}
            sx={{mb: 2}}
            fullWidth
            onChange={(event) => setPassword(event.target.value)}
            type={'password'}/>
          <br/>

          <LoadingButton
            type={'submit'}
            loading={loading}
            variant="outlined">
            Anmelden
          </LoadingButton>
          <Button onClick={() => navigate(navigation.legal.path)}>{navigation.legal.name}</Button>
          <Button onClick={() => navigate(navigation.privacy.path)}>{navigation.privacy.name}</Button>
        </form>
      </Grid>
    </Grid>
    <Grid item xs={6} sx={{
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: 'cover',
    }}>

    </Grid>
  </Grid>)
}

export default Login
