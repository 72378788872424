export interface Environment {
  name: string
  baseUrl: string
  cognito: { userPoolId: string, clientId: string }
}

const test: Environment = {
  name: 'test',
  baseUrl: 'https://api.t.enmonitor.de',
  cognito: {
    userPoolId: 'eu-central-1_J6f3vNcxe',
    clientId: '5qrgb4etto3cgvbqn81l7iudk2'
  },
}

const environments: { [key: string]: Environment } = {
  'localhost': test,
  'beta-test.enmonitor.de': test,
  'dev.enmonitor.de': {
    name: 'test',
    baseUrl: 'https://api.dev.enmonitor.de',
    cognito: {
      userPoolId: 'eu-central-1_3I1aj745Y',
      clientId: '5av146dc352fibtjq87id5rln4'
    },
  },
  't.enmonitor.de': test,
  'new.enmonitor.de': {
    name: 'test',
    baseUrl: 'https://api.new.enmonitor.de',
    cognito: {
      userPoolId: 'eu-central-1_v66XRT24b',
      clientId: '1jmqicppeclej81r4bvvphppc4'
    },
  }
}

export const environment = (key: string): Environment => {
  const env = environments[key]
  if (env === undefined) {
    throw new Error(`No environment for "${key}" found!`)
  }
  return env
}
