import PageHeader from './PageHeader'
import React, {useContext} from 'react'
import {UserContext} from './UserContext'
import Greeting from './Greeting'
import Section from './Section'


const Dashboard: React.FC = () => {
  const {user} = useContext(UserContext)
  if (user === null) {
    return <></>
  }

  const pageHeader = <PageHeader
    title={'Übersicht'}
    description={`Alles Wichtige auf einen Blick`}
  />

  return (
    <Section pageHeader={pageHeader}>
      <Greeting user={user}/>
    </Section>
  )
}

export default Dashboard
