export interface NavigationItem {
  path: string
  name: string
}

const navigation: { [id: string]: NavigationItem } = {
  login: {
    name: "Anmeldung",
    path: "/login"
  },
  privacy: {
    name: "Datenschutz",
    path: "/privacy"
  },
  legal: {
    name: "Impressum",
    path: "/legal"
  },
  alarms: {
    name: "Alarme",
    path: "/app/alarms"
  },
  alarmCreate: {
    name: "Alarm erstellen",
    path: "/app/alarms/create"
  },
  alarmUpdate: {
    name: "Alarm bearbeiten",
    path: "/app/alarms/update"
  },
  reports: {
    name: "Berichte",
    path: "/app/reports"
  },
  reportsCreate: {
    name: "Bericht erstellen",
    path: "/app/reports/create"
  },
  reportsUpdate: {
    name: "Bericht bearbeiten",
    path: "/app/reports/update"
  },
  companies: {
    name: "Unternehmen",
    path: "/app/companies"
  },
  companiesCreate: {
    name: "Unternehmen erstellen",
    path: "/app/companies/create"
  },
  companiesUpdate: {
    name: "Unternehmen bearbeiten",
    path: "/app/companies/update"
  },
}

export default navigation
