import PageHeader from './PageHeader'
import React from 'react'
import Breadcrumbs from './Breadcrumbs'
import Section from './Section'
import {Typography} from '@mui/material'


const CostsOverview: React.FC = () => {
  const title = 'Kosten'
  const pageHeader = <PageHeader
    title={title}
    description={'Die Übersicht aller Kosten'}
  />
  return <>
    <Breadcrumbs current={title}/>
    <Section pageHeader={pageHeader}>
      <Typography>Dieser Bereich befindet sich im Aufbau.</Typography>
    </Section>
  </>
}

export default CostsOverview
