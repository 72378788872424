import React, {useState} from 'react'
import {doLogout, getSession, User, UserContext} from './UserContext'
import {NavigateFunction, Route, Routes, useLocation, useNavigate} from 'react-router-dom'
import App from './App'
import Login from './Login'
import AlarmsOverview from './alarms/AlarmsOverview'
import CostsOverview from './CostsOverview'
import DiagramsOverview from './DiagramsOverview'
import ImportExportOverview from './ImportExportOverview'
import Dashboard from './Dashboard'
import AlarmCreate from './alarms/AlarmCreate'
import navigation from './Navigation'
import Privacy from './Privacy'
import Legal from './Legal'
import ReportsOverview from './reports/Overview'
import ReportsEdit from './reports/Edit'
import CompaniesOverview from './companies/Overview'
import CompaniesEdit from './companies/Edit'


const Loading: React.FC = () => {
  return (<>Loading...</>)
}

const MetaApp: React.FC = () => {
  const [user, setUser] = useState<User | null>(null)
  const [loading, setLoading] = useState(true)
  const navigate: NavigateFunction = useNavigate()
  const location = useLocation()

  React.useEffect(() => {
      if (location.pathname === navigation.legal.path || location.pathname === navigation.privacy.path) {
        return
      }

      if (user !== null) {
        return
      }
      getSession()
        .then(value => {
          const currentPath = location.pathname === "/" ?
            "/app/home" :
            location.pathname

          setLoading(false)
          if (currentPath !== "/login") {
            if (value === null) {
              navigate("/login", {state: {previousPath: currentPath}})
            } else {
              setUser(value)
              navigate(currentPath)
            }
          }
        })
        .catch(e => {
          switch (e.name) {
            case "NotAuthorizedException":
              doLogout().then(() => {
                setUser(null)
                navigate('/login')
              })
              break
            default:
              console.error('Failed', e)
              break
          }
        })
    }, [user, loading, setLoading, navigate, location.pathname]
  )

  if (loading) {
    return (<Loading/>)
  }

  return (
    <UserContext.Provider value={{user, setUser}}>
      <Routes>
        <Route path={"/"} element={<></>}/>
        <Route path={navigation.login.path} element={<Login/>}/>
        <Route path={navigation.privacy.path} element={<Privacy/>}/>
        <Route path={navigation.legal.path} element={<Legal/>}/>
        <Route path="/app" element={<App/>}>
          <Route path="/app/home" element={<Dashboard/>}/>
          <Route path={navigation.alarms.path} element={<AlarmsOverview/>}/>
          <Route path={navigation.alarmCreate.path} element={<AlarmCreate/>}/>
          <Route path={navigation.alarmUpdate.path} element={<AlarmCreate/>}/>
          <Route path={navigation.reports.path} element={<ReportsOverview/>}/>
          <Route path={navigation.reportsCreate.path} element={<ReportsEdit/>}/>
          <Route path={navigation.reportsUpdate.path} element={<ReportsEdit/>}/>
          {
            user?.role === "ADMINISTRATOR" ?
              <>
                <Route path={navigation.companies.path} element={<CompaniesOverview/>}/>
                <Route path={navigation.companiesCreate.path} element={<CompaniesEdit/>}/>
                <Route path={navigation.companiesUpdate.path} element={<CompaniesEdit/>}/>
              </> :
              <></>
          }
          <Route path="/app/diagrams" element={<DiagramsOverview/>}/>
          <Route path="/app/costs" element={<CostsOverview/>}/>
          <Route path="/app/import-export" element={<ImportExportOverview/>}/>
        </Route>
      </Routes>
    </UserContext.Provider>
  )
}


export default MetaApp

