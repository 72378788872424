import React, {useEffect} from 'react'
import {Autocomplete, TextField} from '@mui/material'
import Meter from './Meter'


interface Props {
  id: string
  label: string
  values: Meter[]
  value: Meter | null
  onChange: (value: Meter | null) => void
}

const MeterAutocomplete: React.FC<Props> = ({id, label, values, onChange, value}) => {
  const [autocompleteValue, setAutocompleteValue] = React.useState<Meter | null>(value)
  useEffect(
    () => onChange(values.find(m => m.id === autocompleteValue?.id) || null),
    [autocompleteValue, onChange, values])
  return <Autocomplete
    id={id}
    value={value}
    onChange={(event, value) => setAutocompleteValue(value)}
    options={values}
    getOptionLabel={(option) => option.label}
    renderInput={(params) => (
      <TextField
        {...params}
        required
        label={label}
        inputProps={{
          ...params.inputProps,
          // disable autocomplete and autofill
          autoComplete: 'new-password',
        }}
      />
    )}
  />
}

export default MeterAutocomplete
