import React from 'react'
import {Typography} from '@mui/material'


const Legal: React.FC = () => {
  return <>
    <Typography variant={"h2"}>enmonitor.de</Typography>
    <Typography>ein Service der</Typography>
    <Typography>Transfer Industrie Forschungsgesellschaft (TIF)</Typography>
    <Typography>Emil-Figge-Straße 43</Typography>
    <Typography>44227 Dortmund</Typography>
    <Typography>Mail: support@enmonitor.de</Typography>
    <Typography>Tel.: +49 231 / 13 013 899</Typography>
  </>
}

export default Legal
