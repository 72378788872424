import React, {FormEvent, useContext, useEffect, useState} from 'react'
import navigation from '../Navigation'
import Breadcrumbs from '../Breadcrumbs'
import PageHeader from '../PageHeader'
import Section from '../Section'
import {Box, Button, Grid, TextField, Typography} from '@mui/material'
import {doLogout, UserContext} from '../UserContext'
import {useLocation, useNavigate} from 'react-router-dom'
import {LoadingButton} from '@mui/lab'
import {Company} from '../Company'
import styled from '@emotion/styled'
import {changeCompany, deleteCompany, upsertCompany} from '../Api'


const title = (isEdit: boolean): string => isEdit ?
  navigation.companiesUpdate.name :
  navigation.companiesCreate.name

type Loading = "NONE" | "UPDATE" | "DELETE" | "COMPANY"

const Input = styled('input')({
  display: 'none',
})


const Edit: React.FC = () => {
  // data from state
  const location = useLocation()

  const navigate = useNavigate()

  const {user} = useContext(UserContext)

  // data from api
  const [isEdit, setIsEdit] = useState<boolean>(false)

  // form values
  const [id, setId] = useState<string | undefined>()
  const [name, setName] = useState("Unbenanntes Unternehmen")
  const [logo, setLogo] = useState<string | null>(null)
  const [files, setFiles] = useState<FileList | null>(null)

  const [loading, setLoading] = useState<Loading>("NONE")

  const onSubmit = (event: FormEvent) => {
    event.preventDefault()
    if (user === null) {
      return
    }
    event.preventDefault()
    setLoading("UPDATE")

    upsertCompany(user, {
      id,
      name,
      files,
    })
      .then(response => {
        response
          .success(() => navigate(navigation.companies.path))
          .error(e => {
            console.error(e)
            setLoading("NONE")
          })
      })
  }

  useEffect(() => {
      if (location?.state?.company === undefined || location?.state?.company === null) {
        return
      }
      const company: Company = location.state.company
      setIsEdit(true)
      setId(company.id)
      setName(company.name)
      if (company.logo !== undefined) {
        setLogo(company.logo)
      }
    },
    [location]
  )

  const handleCompanySwitch = () => {
    if (user === null || id === undefined) {
      return
    }
    setLoading("COMPANY")
    changeCompany(user, id).then(result => result
      .success(() => {
        doLogout().then(result => result
          .success(() => console.info("Logging out. Reason: company change."))
          .error(() => console.error("Error during logout"))
          .finally(() => navigate(navigation.login.path))
        )
      })
      .error(value => {
        setLoading("NONE")
        console.log("company switch: failure")
        console.error(value)
      })
    )
  }

  const handleDelete = () => {
    if (user === null || id === undefined) {
      return
    }
    setLoading("DELETE")
    deleteCompany(user, id)
      .then(result => result
        .success(_ => {
            setLoading("NONE")
            navigate(navigation.companies.path)
          }
        )
        .error(value => {
          setLoading("NONE")
          console.error(value)
        })
      )
  }

  return (<>
    <Breadcrumbs previous={[navigation.companies]} current={title(isEdit)}/>
    <form onSubmit={onSubmit}>
      <Grid container>
        <Grid item xs={8}>
          <Section
            pageHeader={
              <PageHeader
                title={'Allgemeine Informationen'}
                titleSize={"xs"}
              />
            }>
            <TextField
              required
              label={"Name"}
              name={"name"}
              fullWidth
              value={name}
              onChange={e => setName(e.currentTarget.value)}
            />

            {
              logo === null ?
                <></> :
                <Box mt={2}>
                  <Typography variant={"caption"}>Logo</Typography>
                  <Box
                    width={"200px"}
                    height={"200px"}
                  >
                    <img
                      src={`data:image/png;base64,${logo}`}
                      alt="Unternehmenslogo"
                      width={"100%"}
                    />
                  </Box>
                </Box>
            }

            <Box sx={{mt: 2}}>
              <label htmlFor="contained-button-file">
                <Input
                  accept={"image/*"}
                  id="contained-button-file"
                  type="file"
                  onChange={e => setFiles(e.currentTarget.files)}
                />
                <Button
                  variant="contained"
                  component="span"
                >
                  neues Logo auswählen
                </Button>
              </label>
            </Box>
          </Section>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            mt={2}
          >
            <Grid item>
              <LoadingButton
                loading={loading === "COMPANY"}
                disabled={loading !== "NONE"}
                variant="outlined"
                sx={{mr: 2}}
                onClick={handleCompanySwitch}
              >
                Wechseln und abmelden
              </LoadingButton>
              {
                isEdit ?
                  <LoadingButton
                    loading={loading === "DELETE"}
                    disabled={loading !== "NONE"}
                    variant="outlined"
                    onClick={handleDelete}
                    sx={{mr: 2}}
                  >
                    Unternehmen löschen
                  </LoadingButton> :
                  <></>
              }
              <LoadingButton
                loading={loading === "UPDATE"}
                disabled={loading !== "NONE"}
                variant={'contained'}
                type={"submit"}>
                Unternehmen speichern
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  </>)
}

export default Edit
